









import Vue from "vue";

export default Vue.extend({
  name: "MemberApplicationFailed",
  computed: {},
  data: () => {
    return {};
  },
  methods: {
    returnToMemberApplicationPage() {
      this.$router.push({
        name: "Member Application",
      });
    },
  },
});
